.l-footer {
  background-color: $footer-bg;
  color: $gray-400;
  p {
    line-height: 1.6;
}
}

.footer_before {
  > div {
    min-height: $spacer*12;
  }
  p {
    color:$gray-400
  }
  .btn {
    background-color: $secondary;
    border-radius: $btn-radius;
    padding: $spacer $spacer*1.5;
  }

  h3 {
    font-family: 'Arboria', 'Jost', sans-serif;
    font-size: 3rem;
    max-width: 330px;
    line-height: 1em;
  }
} 

.footer-row a {
  &:hover {
    color: $primary;
  }
}


.footer-container {
  margin-top: $footer-container-margin-top;
}

.footer-bar__left {
  column-gap: $spacer * 1.5;
  row-gap: $spacer;
}
.link-block {
  li {
    margin-bottom: $spacer/4;
  }
  a {
    @include transition-fast;
  }
}

.block-contact .footer-contact-block {
  > a {
    display: inline-block;

  }
  gap: $spacer * 0.5;
  margin-bottom: $spacer;
}

.footer__title {
  &:visited,
  &:hover {
    color: inherit;
  }
  display: block;
  margin-bottom: $spacer;
  &--mobile {
    display: none;
    margin-bottom: $spacer;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;

    &:after {
      content: "\e313";
      font-family: "Material Icons";
      font-feature-settings: "liga" 1;
      position: absolute;
      right: $grid-gutter-width/2;
      @include transition(0.3s transform ease-in-out);
      font-size: $font-size-base * 1.5;
      font-weight: 100;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform: rotate(180deg);
    }
  }
}

.footer__copyright {
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav {
  align-items: center;
}
.header-nav__nav2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top {
  display: flex;
  justify-content: space-between;
  position: static;
}

.footer-top{
  font-size: $font-size-base*1.75;
  border-bottom: solid 1px transparentize($white, 0.7);
  a {
    letter-spacing: 0.05rem;
  }
  @include media-breakpoint-down(lg) {
    font-size: $font-size-base;

    svg {
      max-width: 200px;
    }
  }
}
@include media-breakpoint-desktop {
  .footer-top-content >div:not(:last-of-type) {
    border-right: solid 1px transparentize($white, 0.7);
  }
}


@include media-breakpoint-mobile {
  .footer-row {
    .footer-logo,
    .link-block {
      flex-basis: 100%;
    }
    .block-contact {
      order: 1;
    }
  }
  .footer__title {
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
  }
  .footer-bar__right {
    flex-grow: 1;
  }
}
