.block_newsletter {
    input[type=email]{
        border-radius: $btn-radius;
        border-right: 0;
        &:focus {
          z-index: 0;
        }
        button {
          border-radius: $btn-radius;
        }}
    
    .input-group>.form-control, .input-group>.input-group-append>.btn {
        border-radius: $btn-radius;
    }

    .input-group-append {
        margin-left: -$spacer;
        }
    .newsletter-icon {
        width: $spacer*3;
        height: $spacer*3;
    }

    @include media-breakpoint-desktop {
        border-right:solid 1px $gray-200;
        input[type=email] {
            min-width: $spacer*20;
        }
    }


    @include media-breakpoint-down(sm) {
        form {
            display: flex;
            flex-direction: column-reverse;
        }
        .input-group {
            flex-direction: column;
            align-items: center;
        }

        .input-group>.form-control, .input-group>.input-group-append>.btn {
            border-radius: $border-radius-lg!important;
            margin-bottom: $spacer;
        }

        .input-group>.form-control{
            width: 100%;
        }
        input[type=email]{
            border-right: solid 1px $gray-200;}

        .custom-control-label {
            text-align: left;
            font-size: $font-size-base*.875;
        }
    }

}