img[data-lazy] {
  display: none;
}
.text-underline{
  text-decoration: underline;
}
.cursor-pointer{
  cursor: pointer;
}

.visible--desktop{
  @include media-breakpoint-desktop(down) {
    display: none;
  }
}
.visible--mobile{
  @include media-breakpoint-desktop(){
    display: none;
  }
}
.d--none{
  display: none;
}
.d--block{
  display: block;
}

.full-bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}
.text--center{
  text-align: center;
}
.text--right{
  text-align: right;

}
.u-m-l-2{
  margin-left: $spacer/2;
}
.u-bor-bot{
  border-bottom:1px solid $border-color;
}


/* Font Size Tiny */

.tiny {
  font-size: $tiny-font-size;
}

/* Icon Sizing */

.icon-xs {
  width: $icon-xs-size;
  height: $icon-xs-size;
}

.icon-sm {
  width: $icon-small-size;
  height: $icon-small-size;
}

// .icon {
//   width: $icon-normal-size;
//   height: $icon-normal-size;
// }

.icon-lg {
  width: $icon-large-size;
  height: $icon-large-size;
}

.icon-xl {
  width: $icon-xl-size;
  height: $icon-xl-size;
}

.icon-2xl {
  width: $icon-2xl-size;
  height: $icon-2xl-size;
}

/* Colors */

.fill-green {
  fill: $green;
}

/* Ripple */

span.ripple {
  position: absolute;
  pointer-events: none;
  @include border-radius(50%);
  transform: scale(0);
  animation: ripple 500ms ease-out;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 21;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/* View more */

.view-more {
  a {
    color: $text-black;
    font-weight: $weight-medium;
    font-size: $display5-size;
    @include transition-fast;
    &:hover {
      color: $primary;
    }
  }
  svg {
    stroke-width: 1;
    width: $icon-small-size;
    height: auto;
    margin-left: $spacer * .25;
  }
}