.slick-arrow {
    position: absolute;
    top:45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 1;
    overflow: visible;

    & > i{
        font-size: 2*$font-size-base;
        line-height: 1;
        background: $white;
        @include border-radius(50%);
    }
    &:hover {
            border: none;
            background: none;
            overflow: visible;
    }

 }
.slick-prev {
    left:-$spacer*1.5;
}
.slick-next{
    right:-$spacer*1.5;
}
.slick-slide figure{
    margin: 0;
}
.slick__arrow-outside {
    .slick-prev {
        transform:translateX(-50px);
    }
    .slick-next{
        transform:translateX(50px);
    }
}
.slick__arrow-large{
    .slick-arrow i{
        font-size: 3*$font-size-base;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
        background: transparent;
        color: $black;
    }
}