// @import url(https://db.onlinewebfonts.com/c/1c7f14203d028f1c6f8914508f469cb9?family=Arboria-Book);

@font-face {
  font-family: "Arboria";
  src: url("/themes/profiregaly/assets/fonts/Arboria.ttf");
  src: url("/themes/profiregaly/assets/fonts/Arboria.otf")format("otf"),
  url("/themes/profiregaly/assets/fonts/Arboria.ttf")format("truetype");
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: unset;
}
.hidden {
  display: none;
}
.fw-black {
  font-weight: $weight-black;
}
.fw-bold {
  font-weight: $weight-bold;
}

.fw-med {
  font-weight: $weight-medium;
}

.fw-reg {
  font-weight: $weight-regular;
}
.icon {
  fill: $primary;
}
.config-btn {
  background: radial-gradient(at right bottom, lighten($secondary,30%), $secondary); ;
  svg {
    fill: $primary;
  }

  &:hover {
    svg {
      fill: $white;
    }
  }
}

.icon-primary {
  fill: $primary;
}

.icon-secondary {
  fill: $secondary;
}

.icon-white {
  fill: $white;
}

.icon-gray {
  fill: $font-light;
}

.stroke-secondary {
  stroke: $secondary;
}

.fs-2 {
  font-size: $display2-size;
  @include media-breakpoint-down(md) {
    font-size: $display3-size;
  }
}

.fs-3 {
  font-size: clamp($spacer * 1.75, 3vw, #{$display3-size});
}

.fs-4 {
  font-size: $display4-size;
}

.fs-reg {
  font-size: $font-size-base;
}

.fs-sm {
  font-size: $small-font-size;
  line-height: 1.6;
}

.fs-xs {
  font-size: $font-size-base * 0.75;
}

.text-gray {
  color: $font-light;
}

.bg-tertiary {
  background-color: $tertiary;
}

.big-rad {
  border-radius: $btn-radius;
}

.sm-rad {
  border-radius: $border-radius-lg;
}

.g-1 {
  gap: $spacer;
}

.g-2 {
  gap: $spacer * 2;
}

img:not(.img-cover):not(.img-contain) {
  max-width: 100%;
  height: auto;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6 {
  display: grid;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-6 {
  grid-template-columns: repeat(6, 1fr);
}

@include media-breakpoint-down(lg) {
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-5, .grid-6 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@include media-breakpoint-down(md) {
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-5, .grid-6 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-2 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@include media-breakpoint-down(sm) {
  .grid-5,.grid-6 {
    grid-template-columns: repeat(2, 1fr);
  }

}

@include media-breakpoint-down(xs) {
  .grid-2,
  .grid-3,
  .grid-4,
  .grid-5,
  .grid-6 {
    grid-template-columns: repeat(1, 1fr);
  }
}

// .ui-tooltip.ui-widget-content


.tooltip {
  z-index: 99999999999999 !important;}


  .tooltip .tooltip-inner, .ui-tooltip.ui-widget-content {
    box-shadow: none;
    background: white;
    border: solid 1px $gray-300;
    color:$gray-600;
    border-width: 1px;
    font-size: $font-size-base*.875;
    font-family: 'Jost', sans-serif;
    padding: $spacer/2;
    opacity: 1;
  }

  .bs-tooltip-top .arrow::before{
    border-color: transparent!important;
  }


#carousel {
  height: 100%;
  max-height: auto;
  @include transition-slow;
  &::before {
    content: "";
    background: url(/themes/profiregaly/assets/img/loader.svg) no-repeat center;
    background-size: $spacer * 4;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &:not(.slick-initialized) {
    max-height: 608px;
    @include transition-slow;
    .carousel-image {
      visibility: hidden;
      @include transition-slow;
    }
  }
  &.slick-initialized {
    max-height: auto;
    .carousel-image {
      visibility: auto;
    }
  }

  h1, h2 {
    font-family: 'Arboria', 'Jost', sans-serif;
  }
}

main {
  background: url("/themes/profiregaly/assets/img/bg.svg") center center
    no-repeat;
  position: relative;
  padding-top: $spacer*15;
  z-index: 1500;
  @include media-breakpoint-down(lg) {
    padding-top: $spacer*8;
  }
  @include media-breakpoint-down(sm) {
    padding-top: $spacer*6;
  }
}

#cart #main {
  position: relative;
  z-index: 1500;
}

.alert-warning {
  background-color: $tertiary;
  border-color: $tertiary;
  color: $primary;
}
.alert-info {
  background-color: $primary;
  border-radius: $border-radius-lg;
  color: $white;
  &.selected {
    background-color: $gray-300;
    color: $secondary;
  }
}

#category-description-full {
  display: none;
}

a {
  color: inherit;
}

p a {
  color: $primary;
}
.card {
  @include transition-fast;
  a {
    @include transition-fast;
  }
  border: solid 1px $gray-200;
  &.hover:hover {
    //   box-shadow: $box-shadow-lift-up;
    //   transform: translateY(-0.25rem);
    border-color: $gray-300;
  }
}

// Button

.back-button svg {
  margin-bottom: 2px;
}

.back-button:hover svg {
  fill:$primary
}

select,
input:not([type="checkbox"]):not([type="radio"]):not(
    .js-cart-line-product-quantity
  ),
.custom-file,
.custom-file-input,
.custom-file-label,
.custom-file-label:after,
.custom-select {
  min-height: $spacer * 3.125;
  height: unset;
}

.form-control,
.form-control[type="email"],
.form-control[type="text"]:not([name="qty"]),
.custom-file-input,
.custom-file-label,
.custom-file,
.custom-select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-lg;
  border-color: $gray-200;
}

input[type="number"] {
  &.hide-controls {
    -moz-appearance:textfield;
    -webkit-appearance: none;
    text-align: center;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}


.custom-file-label:after {
  display: flex;
  align-items: center;
}
a:hover {
  color: $primary;
}
.btn, #shaim_ares {
  padding: $spacer*1.25 $spacer*2.25;
  position: relative;
  overflow: hidden;
  z-index: 10;
  border: none;
  border-radius: $border-radius-lg;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-base*1.1;
  svg {
    width: $btn-icon-size;
    height: $btn-icon-size;
  }
  // gap: $spacer * 0.25;
  @include transition-fast;
  box-shadow: none;
  color: $white;
  &:hover {
    opacity: 0.8;
  }

  @include media-breakpoint-down(md) {
    padding: $spacer $spacer * 1.5;
  }
}
.btn.qty-btn {
  padding: $spacer
}

button.btn-add-to-cart img {
  max-width: $spacer * 1.75 !important;
  margin-right: $spacer/2;
}

// .add-to-cart {
//     padding: $spacer*1.25;
//     &[disabled] .icon-secondary {
//         fill: $gray-400;
//     }
// }

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-primary {

  &:hover {
    background: radial-gradient(at right bottom, lighten($primary,30%), $primary);
  }
  &.btn-white:hover {
    color: $primary;
  }

  &:disabled,
  &.disabled {
    color: $white;
    background: $gray-300;
    border-color: $gray-300;
  }
}

.btn-inner {
  border-radius: 0 $border-radius-lg $border-radius-lg 0;
  padding: $spacer;
}

.btn-white {
  background: $white;
  color: $font-color;
  &:hover {
    color: $white;
  }
}

.btn-outline-primary, #shaim_ares {
  border: solid 2px $secondary;
  color: $secondary;
  svg {
    width: $btn-icon-size;
    height: $btn-icon-size;
    opacity: $btn-icon-opacity;
  }

  &:hover {
    color: $white;
    background-color: $secondary;
    border-color: $secondary;
  }

  &.border-white:hover {
    border-color: $primary;
  }
}

.card-body {
  position: relative;
  @include media-breakpoint-mobile(up) {
    padding: ($spacer * 1.5) ($spacer * 1.5);
  }
  @include media-breakpoint-mobile() {
    padding: ($spacer * 1.5) ($spacer * 1.5);
  }
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
  &:before {
    margin: auto $spacer/4;
  }

}

@include media-breakpoint-down(lg) {
  .breadcrumb {
    word-wrap: break-word;
    word-break: keep-all;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: $primary;
    &::-webkit-scrollbar {
      height: 5px;
    }

    ::-webkit-scrollbar-track {
      background: $gray-300;
    }

    ::-webkit-scrollbar-thumb {
      background: $primary;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: $gray-600;
    }

    .breadcrumb-item + .breadcrumb-item {
      padding-left: $spacer;
      display: flex;
    }

    .breadcrumb-item + .breadcrumb-item:before {
      width: $spacer;
      height: $spacer;
      right: $spacer/2;
      background-size: contain;
      bottom: -5px;
    }

    svg {
      width: 12px;
      height: 12px;
      bottom: -4px;
    }
  }
}

#wrapper nav {
  position: relative;
}

#wrapper nav::after {
  content: "";
  width: 3rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(270deg, white, transparent);
  pointer-events: none;
}
// Form

.form-control {
  height: unset;
  box-shadow: none;
  &:focus,
  &:active {
    border-color: $primary;
  }
}
.custom-select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, $secondary 50%),
    linear-gradient(135deg, $secondary 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) calc(1.2rem + 2px),
    calc(100% - 15px) calc(1.2rem + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  box-shadow: none;
}

input[type="radio"]:checked {
  background-color: $primary;
}
input[type="checkbox"]::before {
  box-shadow: inset 1em 1em $primary;
}
input[type="checkbox"]:focus {
  outline-color: $primary;
}

input:focus-visible {
  outline: none !important;
  border: none;
}

.custom-control-input:checked ~ .custom-control-label:before {
  background-color: $primary;
}

// blog

.blog-hero-content {
  background: linear-gradient(162deg, $primary, $primary-accent);
  color: $white;
  top: 0;
  min-height: 100%;
  width: 50%;

  &::before {
    content: "";
    background: url(/themes/profiregaly/assets/img/slider-bg.svg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    // background: linear-gradient(162deg, #1d1ea5f0, #2950e7cf);
    min-height: $spacer * 27;
  }

  * {
    z-index: 99999;
  }
  h1 {
    font-size: clamp($spacer * 1.75, 3vw, #{$display2-size});
  }
}

.blog-hero {
  min-height: $spacer*30;
}

.blog-content {
  @include media-breakpoint-up(md) {
    section {
      margin:$spacer*5 0;
    }
  }
  
  h2 {    
      font-weight:  $weight-medium;
      color:$secondary;
      font-size: clamp($spacer * 1.75, 3vw, $font-size-base*3);
      margin-bottom: $spacer*1.75;
  }
  .blog-usp-box h3 {    
    font-weight:  $weight-medium;
    font-size: clamp($spacer * 1.75, 3vw, $font-size-base*1.5);
    position: relative;
}
}
 
.metaComment.article-info {
  display: none;
}
.post_image {
  img {
    width: 100%;
    @include transition-fast;
    max-height: $spacer*14;
    object-fit: cover;
  }

  &:hover img {
    @include zoomin;
  }
}
.blog-usp-box {
  position: relative;
}

.inner-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0.15;
  object-fit: cover;
}

h2.sdsarticleTitle {
  font-size: $font-size-base;
}

section.lastestnews::before,
section.lastestnews:after {
  content: "";
  background: linear-gradient(150deg, #ff6c00, #ffb400);
  width: 100%;
  height: calc(100% - 330px);
  position: absolute;
  bottom: 0;
  left: 0;
  @include media-breakpoint-down(lg) {
    height: calc(100% - 200px);
  }
}

section.lastestnews:after {
  background: url(/themes/profiregaly/assets/img/bg-white.svg) no-repeat;
  height: 100%;
  background-size: cover;
  z-index: 0;
  @include media-breakpoint-down(lg) {
    height: 100%;
  }
}

.lastestnews {
  .l-wrapper {
    z-index: 2;
  }
}

#module-smartblog-category main {
  position: relative;
  &::before {
    content: "";
    background: linear-gradient(150deg, #ff6c00, #ffb400);
    width: 100%;
    height: calc(100% - 200px);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.lastest_posts {
  @include media-breakpoint-down(xl) {
    li {
      margin-bottom: $spacer * 2;
    }
  }

  @include media-breakpoint-down(lg) {
    li:last-child {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    li:last-child {
      display: inherit;
    }
  }
}

#currency-btn {
  color: $font-color;
  &:hover {
    background: transparent;
  }

  img {
    width: 25px;
  }

  @include media-breakpoint-mobile-menu(down) {
    padding: 0 $spacer/4;
  } 
}


#mobile-icons {
  position: fixed;
  right: -28px;
  top: $spacer * 12;
  z-index: 99999999;
  transform: rotate(90deg);
  a {
    width: $spacer * 3;
    height: $spacer * 3;
    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
  }

  svg {
    transform: rotate(-90deg);
  }
}

// guest tracking
#guestOrderTrackingForm .alert p:last-child {
  margin-bottom: 0;
}

button.wishlist-button-add {
  box-shadow: none;
  background-color: none;
}

#password,
#authentication.page-customer-account {
  #content-wrapper {
    max-width: $spacer * 60;
    border: solid 1px $gray-200;
  }

  .form-group a, .no-account a {
    text-decoration: underline;
  }

  .form-group.col-lg-6 {
    max-width: 100%;
  }

  .login-form {
    .form-group {
      padding: 0;
    }
  }
}

/* wishlist */

.nxtal-wishlist-icon {
  i, svg {
    font-size: $spacer * 2.25;
    cursor: pointer;
  }

  .hover {
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0;
    @include transition-fast;
  }

  &.active {
    .hover {
      opacity: 1;
    }

    .default {
      opacity: 0;
    }
  }
}

.product-additional-info .nxtal-wishlist-icon svg {
  max-width: $spacer*1.2;
  max-height: $spacer*1.2;
  margin-right: 0 !important;
  fill: #5e5e5e;
}

/* compare */
.product-miniature .compare-pm {
  top: 0;
  right: 0;
}

.compare-pp__btn--added svg{
  fill: $primary;
}

.product-additional-info a:hover svg {
  fill: $primary;
}

/* calculator */

#calculators {

  width: 100%;
  form {
  padding: 0.75rem 2rem;
  font-size: $small-font-size;
  .group-column.second {
  font-size: $font-size-base;
  }


  }

  ul.activated {
    left: 0;
    background: white;
    width: 100%;
    border-radius: $border-radius-lg;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-color: $gray-200;
    height: auto !important;
    li {
      padding: 0 $spacer;
      text-align: left;
      padding: $spacer/4 $spacer;
      
      &:not(:last-child) {
        border-bottom: solid 1px #e9e9e9;
      }
    }
  }

  ul:not(.activated) li.selected{
    overflow: hidden;
    max-height: $spacer*2;
}

  div#calculator-select .param {
    display: flex;
    max-width: 18rem;
    @include media-breakpoint-down(sm){
      max-width: unset;
    }
    .list {
      width: 100%;
      min-height: $spacer * 2.5;
      position: relative;

      li {
        padding: 0 $spacer;
        text-align: left;
        min-height: $spacer * 2.5;
        &:not(first-child) {
          padding: $spacer/2 $spacer;
        }
        &.selected {
          display: flex !important;
          align-items: center;
        }
      }

      ul {
        height: 100%;
      }

      ul.activated {
        background: white;
        li {
          display: flex !important;
          align-items: center;
        }
      }
    }

    .toggle {
      // width: $icon-xl-size;
      height: $icon-xl-size;
    }
  }
  .is-calculator {
    // display: grid;
    // grid-template-columns: 3fr 5fr;
    // gap: $spacer * 1.25;

    // @include media-breakpoint-down(lg) {
    //   grid-template-columns: 1fr 1fr;
    // }
    // @include media-breakpoint-down(md) {
    //   grid-template-columns: 1fr;
    // }
  

  
  
  }
  i {
    position: relative;
    font-style: normal;
    &.glyphicon-plus, &.glyphicon-minus {
      font-size: $font-size-base*1.25;
    }
    
    &.glyphicon-chevron-down::before,
    &.glyphicon-chevron-down::before {
      content: "";
      background: url(/themes/profiregaly/assets/img/arrow-right.svg) no-repeat;
      background-size: contain;
      width: 2rem;
      height: 2rem;
      position: absolute;
      transform: rotate(90deg);
      right: 0;
      top: 0;
      background-position: center;
    }

    &.glyphicon-chevron-up::before {
      transform: rotate(-90deg);
    }

    &.glyphicon-plus::before,
    &.glyphicon-minus::before {
      content: "+";
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 0;
      top: 0;
    }

    &.glyphicon-minus::before {
      content: "-";
    }
  }

  .param:not(.rack-group-total-width):not(.rack-group-total-price) {
    background: white;
    min-height: $spacer * 2.5;
    height: unset;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: $border-radius-lg;
    border-color: $gray-200;
    align-items: center;
    justify-content: space-between;
    max-width: 18rem;
    float: right;
    width: 100%;
    margin-bottom: $spacer/4;

    &.data-select {
      display: flex;
      .list {
        padding-left: $spacer;
        width: 100%;
      }
      ul.activated {
        top: -10px;
      }
    }
    &.switcher {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      overflow: hidden;
      span {
        height: 100%;
        @include flex-center;
        background: $gray-300;
        &.selected {
          background: $primary;
        }
      }
    }
    &.qty {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      .minus {
        order: -1;
      }
      input {
        text-align: center;
        width: 100%;
        min-height: $spacer * 2.5;
      }

      @include media-breakpoint-down(sm) {
        max-width: $spacer*7;
      }
    }

    &.dd-3 {
      max-width: 9rem;
      display: flex;
      position: relative;
      justify-content: flex-start;
      float: left;
      input {
        max-width: 3rem;
      }
    }

  }

  a.toggle.plus.add-to-basket,
  .toggle.add-rack,
  .toggle.remove-rack,
  .toggle.aright,
  .toggle.aleft {
    display: none;
  }

  
  .setupWrap,
  .value.pRSheight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      margin-right: 1rem;
    }
  }
  .setupWrap {
    margin-bottom: $spacer * 2;
    margin-top: $spacer * 2;
    @include media-breakpoint-down(md){
      flex-direction: column;
      align-items: flex-start;
      #paletteRacks_firstShelfRackBearing_result {
        text-align: left;
    }
    }
  }

  .viewerWrap {
    position: sticky;
    top: $spacer * 5;
  }

  .group-setup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    align-items: self-end;
    // .group-column.first {
    //   display: grid;
    //   align-items: center;
    //   grid-template-columns: 1fr 2fr;
    //   gap: $spacer * 1.25;
    // }
    // .group-column.second {
    //   margin-top: 2rem;
    //   display: grid;
    //   grid-template-columns: 3fr 1fr;
    //   gap: $spacer;
    //   align-items: center;
    //   .row {
    //     display: none;
    //   }
    // }
  }

  .shelf-count-adjust {
    @include flex-center
  }

  .add-remove-setup {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    > div {
      min-width: max-content;
      margin-top: $spacer/4;
    }
  }

  .rack-group-total-width,
  .rack-group-total-price {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding: $spacer/2 $spacer $spacer/2 0;
  }
  .rack-group-total-price {
    align-items: flex-start;
  }

  .noUi-target,
  .noUi-handle {
    box-shadow: none;
  }

  .noUi-target {
    border: none;
    background: $gray-300;
  }

  h1 {
    font-size: clamp($spacer * 1.75, 3vw, #{$display3-size});
    text-align: center;
  }

  .label {
    margin-bottom: 0;
  }

  .infoText,
  .closeableTooltip,
  .rackInfoAlert {
    font-size: $font-size-base;
    margin-bottom: $spacer/2;
    border-radius: $border-radius-lg;
    position: relative;
    padding-left: 3px;
    &::before {
      content:"-";
      position: relative;
      left: -2px;
      top: 0;
    }
  }



  .setup.add-to-basket {
    display: flex;
    width: 100%;
    margin-top: $spacer;
    justify-content: flex-end;
    a, .dd-3 {
      margin: $spacer/2 0 $spacer/2 $spacer/4}
    .param {
      overflow: hidden;
      font-size: $font-size-base;
      a {
        right: 0;
        height: 50%;
        background-color: $gray-300;
        margin: $spacer/4 0;
        @include flex-center;
        &:hover {
          background-color: $primary
        }

      }
  
      .minus {
        bottom: 0;
      }
  
      .plus {
        top: 0;
      }
    }
  }

  

  // &.fixed {
  //   .setup.add-remove-setup {
  //     position: relative;
  //     >div {
  //       margin-left: 0;
  //       margin-right: $spacer/4;
  //     }
  //     .btn-primary {
  //       padding: $spacer*0.875 $spacer;
  //       border: solid 2px $secondary;
  //       color: $secondary!important;
  //       background: none;
  //       path {
  //         fill: $secondary;
  //       }
  //     }

  //     .btn-secondary {
  //       border: solid 2px $secondary;
  //       padding: $spacer*0.875 $spacer;
  //       &:hover {
  //         border-color: $primary;
  //       }
  //     }
  //   }
  // }
}

.btn-secondary:hover {
       background: radial-gradient(at right bottom, lighten($secondary,30%), $secondary);
     }

/* contact page */

.kontakty {

  img {
    border-radius: 100%;
    max-width: 6rem!important;
    margin-top: -4rem;
    margin-bottom: 1rem;
    display: none;
    }

  .row > div {
    background: $tertiary;
    padding: $spacer*2.25;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 12rem;
    text-align: center;
    border-right: solid 1px white;
    color: $gray-600;

    &:first-child {
      border-radius: $btn-radius 0 0 $btn-radius;
    }

    &:last-child {
      border-radius: 0 $btn-radius $btn-radius 0;
      border-right: none;
    }
  }

  h3 {
    font-weight: $weight-bold;
    color: $text-black;
  }

  h4 {
    font-size: $small-font-size;
    margin-bottom: $spacer*1.5;
  }

  a {
    display: block;
  }
}

#contact-form-wrapper > div {
  width: 100%;
  max-width: $spacer*34;
  input, textarea {
    background:none;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px white;
    margin-bottom: $spacer*1.5;
    color: white;
    &:focus, &:hover {
      border-color: $primary;
      box-shadow: none!important;
    }
  }
  ::placeholder {
    color: white;
  }

  
}

#contact-form {
  h1 {
    font-family: 'Arboria', 'Jost', sans-serif;
  }
  .gradient a {
    font-size: $font-size-base*1.5;
  }

  label {
    color: white;
  }

  .agreement label {
    font-size: $small-font-size;
  }

  .preference-box {
    .custom-control {
      padding-left: $spacer*1.5;
    }

    .custom-control-label {

      &::before {
        background: none;
        border-color: white;
        width: $spacer;
        height: $spacer;
        left: -$spacer*1.5;
      }
  
      &::after {
        display: none;
      }
  
    }

    .custom-control-input:checked~.custom-control-label:before {
      background: $primary;
    }

  }
  
}

.gradient {
background: $secondary;
background: radial-gradient(at right bottom, lighten($secondary,30%), $secondary);
}

/* cms */
.page-content .content-layout {
  // font-size: $font-size-base*1.1;
  .row {
    padding: $spacer*3.75 0;
    @include media-breakpoint-down(lg) {
      padding: $spacer*1.75 0;
    }
    @include media-breakpoint-down(sm) {
      &.reversed {
        flex-direction: column-reverse;
      }
    }
    > div {
      overflow: hidden;
      @include media-breakpoint-up(sm) {
        &.minheight {
          min-height: 450px;
        }
      }
    }

    p:last-child {
      margin-bottom: 0;    }
  }

  h2 {
    font-size: clamp($spacer*1.75, 3vW, #{$display3-size});
    margin: 0 0 $spacer*1.25;
  }

  #usp  {
    .title {
      font-size: 1.5rem;
    }
    p {
      max-width: unset;
      color: $gray-500;
    }

  }

  .box-regal-cta{
    h3 {
      font-size: 3rem;
    }
    p {
      font-size: 1.25rem;
      max-width: 350px;
    }
    .btn {
      max-width: max-content;
    }
  }

  .box-regal-content h2 {
    font-size: $font-size-base;
    color: $gray-500;
    strong {
      font-size: clamp($spacer*1.75, 3vW, #{$display3-size});
      color:$gray-900;
      display: block;
    }
  }

  .img-cover {
    position: absolute;
    @include media-breakpoint-down(lg) {
      position: relative;
    }
  }

  .advantages { 
    text-align: center;
  }
}
.blue-box {
  background: linear-gradient(162deg, #1d1ea5, #2950e7);
}

.page-content .box-regal {
  display: grid;
  grid-template-columns: 4fr 5fr;
  align-items: center;
  gap:$spacer;
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr
  }
  img {
    max-height: 24rem;
  }

  a:not(.btn) {
    font-size: $small-font-size;
    font-weight: 400;
    &:hover {
      color: $black;
      border-color: $black!important;
    }
  }

  .box-regal-content {
    height: 100%;
    padding: 1.25rem 0;
  }

  .box-regal-content-cms {
    h2 {
      font-size: 2.125rem;
      text-transform: uppercase;
  }
  .fs-sm {
    font-size: 0.78rem;
  }
  .btn {
    max-width: max-content;
  }
  }

  
}


/* edit 13" */
@media (min-width:992px) and (max-width:1680px) {

#content-wrapper {
  padding: 0;
}

body, #shaim_ares, .btn  {
  font-size: .9rem!important
}

.header-top__content, .fs-sm {
  font-size: 0.85rem;
}

#home-configurator .config-btn {
  font-size: $font-size-base;
}

.product-miniature, .lastest_posts li {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.dots {
  right: 0.5rem!important;
}
.cart-icon.add-to-cart {
  padding: $spacer!important;
}

}

@media (max-width:1620px) {
  .product-miniature .product-buttons >div {
    width: 100% !important;
}
.product-miniature .product-buttons  {
  form, .add-to-cart {
  width: 100%;
  }
  .add-to-cart {
    margin-top: $spacer/2;
  }
}

  }

  @media (max-width:991px) {
  .dots {
    bottom: 0.5rem!important;
  }}