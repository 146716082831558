.block-categories {
  box-shadow:$card-box-shadow;
  position: sticky;
  top:$spacer*10;
}



//block categories
.block-categories {
  a:hover {
    text-decoration:underline
  }
  a {
    position: relative;
    left: $spacer*.75;
    &::before {
      padding-right:$spacer;
      position: absolute;
      left: -$spacer*1.25;
      font-family: 'Material Icons';
      font-size: 18px;
      content: "\e409";
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;  
    }
  }

  li.chosen {

    >div>a{
      font-weight: $weight-bold;}
  }

  .first-line.bg-primary > a{
    color:$white;
}

.first-line.bg-primary > i{
 display:none}
  
}

.cat-heading {
  border-top-left-radius: $btn-radius;
  border-top-right-radius: $btn-radius;
  font-size: $font-size-base*1.25;
  font-weight: $weight-medium;
}

.first-line.bg-primary >div>.close {
  display: none;
}

.first-line:not(.bg-primary) >div>.open {
  display: none;
}
#subcategories {
  .grid-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacer*1.5;

    @include media-breakpoint-down(lg) {
      gap: $spacer;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.subcategory-card {
  img {
    max-width: $spacer*4!important;
  }
}

.category-sub__item{
  display: block;
  width: 100%;
}
.category-sub__item--0{
  padding: $spacer/4 0;
}
.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer/4;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded=true] {
  &.remove{
  display: block;
  }
  &.add{
    display: none;
  }
}

.collapse-icons {
  width: $spacer;
  height: $spacer;
  img {
    right: 0;
    top: $spacer/2;
  }
}


.featured-categories {
  margin-bottom: $spacer * 4;
  .row {
    row-gap: $spacer * 2.5;
  }
  
  .category-thumbnail {
    img {
      width: 100%;
      height: 100%;
      max-height: 20rem;
      padding: 0 ($spacer * 1.5);
      object-fit: scale-down;
    }
  }
  .category-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .category-title {
      color: $text-black;
      font-size: $display4-size;
      font-weight: $weight-medium;
      a {
        color: inherit;
      }
    }
    .view-more a {
      font-size: $font-size-base;
    }
  }
}