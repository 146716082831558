.layout-home {
      .view-more {
        a {
          font-size: $font-size-base;
        }
        svg {
          width: $icon-large-size;
          }
      }

      .cta-section {
        background-color: white;
        h2 {
          margin-bottom: $spacer * .75;
        }
        .description {
          font-size: $h2-font-size;
          font-weight: $weight-regular;
          line-height: $big-line-height;
        }
        &.alternate .gradient {
          background: linear-gradient(90deg, rgba(241,243,246,1) 0%, rgba(252,243,241,1) 100%);
        }
      }
}


.home-categories > div {
  margin: 0 -1rem;
  > a{
    background-color: $tertiary;
    > img {
      max-width: $spacer*15;
    }
  }

  .arr  {
    background-color: $white;
    right: $spacer;
    top: $spacer;
    min-height: $spacer*2.25;
    img {transform: rotate(-45deg);}

  }
}



@include media-breakpoint-down(xl) {
.address-block {
  grid-template-columns: 1fr;
}}

.about-content {
  line-height: 1.6;
}

.cta-content {
  color:$white;
  @include media-breakpoint-down(sm) {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000045;
    left: 0;
    top: 0;
  }
  p, h2 {
    z-index: 2;
  }
  }
}

.slick-dots{
  align-items: center;
  li {   
    width: 0.75rem;
    height: calc(0.75rem +2px);
    margin-right: 0.75rem;
    line-height: 0;
    display: flex;
    align-items: center;
    &.slick-active {
      background: white;
      width: 2.5rem;
      border-radius: 1.75rem;
      border: solid 4px white;
      justify-content: flex-end;
    }
  }
  button {
    border: none;
    font-size: 0;
    border-radius: 100%;
    width: 0.75rem;
    height: 0.75rem;
  }
  .slick-active button {
    background-color: $primary;
  }
} 


.dots {
  bottom: $spacer*3;
}


#discount-slider .homeslider_nav, #discount-slider .slick-arrow {
  display: none!important;
}

.discount-slider-content {
  background:white;
}



#home-configurator {
  .config-btn {
    font-size: $font-size-base *1.5;
    padding: $spacer*1.5 $spacer*2;
}
}