.products {
  align-items: stretch;
}

/* Product miniature*/
.product-miniature {
  margin-bottom: $spacer * 2.5;
  height: auto;

  .product-min-icons {
    right: 0;
    top: 0;
    width: max-content;
}
  
  @include media-breakpoint-down(sm) {
    margin-bottom: $spacer * 1;
  }

  img {
    @include transition-fast;
  }

  &:hover img.img-contain {
    @include zoomin;
  }

  // .add-to-cart {
  //   width: $spacer * 2;
  //   height: $spacer * 2;
  //   bottom: -2px;
  //   background: url(/themes/profiregaly/assets/img/add.svg) no-repeat;
  //   border-radius: 0;
  //   background-size: 30px 28px;
  //   &:hover {
  //     background-image: url(/themes/profiregaly/assets/img/add-hover.svg);
  //     background-size: 30px 28px;
  //   }
  // }

  .nxtal-wishlist-icon i {
    font-size: $font-size-base * 2.3;
    margin-right: $spacer * 0.75;
    bottom: 0;
    position: relative;
  }
}

.card-product {
  height: 100%;
  position: relative;
  background-color: $product-miniature-bg-color;
  border-width: $product-miniature-border-width;
  border-color: $product-miniature-border-color;
  .card-body {
    justify-content: space-between;
  }
  .discount {
    display: none;
  }
}

.card-img-top {
  overflow: hidden;
  .product-thumbnail img {
    width: 100%;
  }
}

.product-flags {
  position: absolute;
  top: 0;
  z-index: 1;
  left: -$spacer/4;
}
.page-content--product .product-flags {
  left: 0;
}

.product-flag {
  top: $spacer * 2;
  left: $spacer * 2;
  background: $secondary;

  &.discount-product {
    background: $primary;
    right: $spacer * 2;
    left: unset;
  }
}

.product-miniature .product-flag {
  width: $spacer * 3.5;
  height: $spacer * 3.5;
  top: $spacer;
  left: $spacer;
  &.discount-product {
    left:$spacer;
    top: 0;
  }
}
.color {
  width: $spacer * 2;
  height: $spacer * 2;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-size: contain;
}

.product-title {
  & a {
    &:hover {
      color: $product-miniature-name-hover-color;
    }
  }
  // .card-product:hover & {
  //   color: $product-miniature-name-hover-color;
  //   & a {
  //     color: $product-miniature-name-hover-color;
  //   }
  // }
}

/* PRODUCT PAGE */

#product .position-sticky {
  top: $spacer * 7;
}

.product-img:not(:first-child) .rc {
  display: none;
}
.product-thumbs {
  max-width: 18%;
  width: 100%;
  padding-right: 1rem;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}
.product-thumb {
  width: $product-thumb-width;
  border: 2px solid transparent;
}

#product .images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  // @include media-breakpoint-up(md) {
  //     max-height: 700px;
  // }
}

.products-imagescover-wrapper {
  max-width: 82%;
  &.full {
    max-width: 100%;
  }

  // @include media-breakpoint-up(md) {
  //     max-height: 700px;
  // }

  @include media-breakpoint-down(sm) {
    height: auto;
    max-width: 100%;
  }
}
.btn-zoom {
  position: absolute;
  right: $spacer;
  bottom: $spacer;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  & .material-icons {
    font-size: 3 * $font-size-base;
    color: $secondary;
  }
}
.images-container {
  &:hover .btn-zoom {
    display: block;
  }
  @include media-breakpoint-down(md) {
    height: auto;
  }
}
.label-color {
  line-height: 1;
  margin-right: $spacer/2;
}
.product-variants-item {
  .color {
    width: $spacer * 3.125;
    height: $spacer * 3.125;
    border: 2px solid $gray-100;
    @include transition-fast;
  }
  .selected .border-primary {
    border-width: 3px;
  }
}

.input-color:checked + span,
.input-color:hover + span,
.input-radio:checked + span,
.input-radio:hover + span {
  border-color: $primary;
}

.tax-shipping-delivery-label {
  font-size: $small-font-size;
  color: $gray-500;
}

.product-information {
  margin-top: $spacer;
}

.product-description,
#category-description,
#category-description-full,
.sdsarticle-des,
.page-content {
  font-size: $font-size-base;
  table {
    max-width: 100%;
    width: 100%;
  }
  h2,
  h3 {
    font-weight: $weight-bold;
    font-size: $font-size-base * 1.25;
    margin-bottom: $spacer/2;
    margin-top: $spacer;
  }

  a:not(.btn) {
    font-weight: $weight-bold;
  }
}

.attachment-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }
}

.attachment-wrapper .btn {
  color: black;
  font-weight: 300;
}

.product-additional-info {
  color: #5e5e5e;
  fill: #5e5e5e;
  .nxtal-wishlist-icon {
    cursor: pointer;
    i {
      font-size: $font-size-base * 1.25;
      bottom: -1px;
      position: relative;
    }
    .default i {
      color: #5d5d5d;
    }
  }

  .nxtal-wishlist-icon.active,
  .compare-pp__btn--added,
  .nxtal-wishlist-icon:hover {
    color: $primary;
    svg {
      fill: $primary;
    }
  }
  a {
    cursor: pointer;
  }
}

.attribute-box:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}

#product {
  .product-reference {
    text-align: right;
    @include media-breakpoint-down(lg) {
      text-align: left;
    }
  }

  .product-page-description p {
    color: $font-light;
  }

  .product-add-to-cart .btn.js-touchspin {
    background-color: $gray-200;
    font-size: $font-size-base * 1.5;
    padding: $spacer $spacer * 1.5;
    min-width: $spacer*4.2;
  }

  .config-btn {
    @include media-breakpoint-down(xl) {
      min-width: 240px;
    }

  }
}

/* Product Discounts */

.tax-shipping-delivery-label.has-discount {
  margin-left: 0 !important;
}

.product-discounts {
  table {
    background: none;
  }
  td {
    border: none;
    text-wrap: nowrap;
  }

  tr {
    &:not(:last-child) {
      border-bottom: solid 1px #e8e8e8;
    }
  }

  .discounts-header {
    white-space: nowrap;
    padding-top: 5px;
  }

  .discounts-table {
    width: 100%;
  }
}

.text-cross {
  text-decoration: line-through;
}

//product prices
.product__product-price {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.product__product-price .current-price {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.price {
  color: $product-price-current-color;
  font-family: $product-price-current-font-family;
  &.current-price-discount {
    color: $product-price-discount-color;
    font-size: $product-price-discount-font-size;
    font-family: $product-price-discount-font-family;
  }
}
.regular-price {
  font-family: $product-price-regular-font-family;
  text-decoration: line-through;
}
.discount {
  text-transform: uppercase;
  color: $white;
  font-weight: 600;
  display: inline-block;
  padding: 0.25 * $spacer;
}

#product-availability span {
  margin-right: $spacer;
}
//product add to cart
.btn-add-to-cart {
  white-space: nowrap;
}
.attributes {
  gap: $spacer * 0.5;
}
//product tabs
.product-tabs {
  .nav-tabs {
    border: 0px;
    font-size: $font-size-base * 0.9;
  }
  .product-description {
    overflow: scroll;
  }
}
.card--reassurance {
  box-shadow: $card-box-shadow;
}

// modal add to cart
.cart-content {
  padding: $spacer;
}

.checkout-summary {
  position: sticky;
  top: 10rem;
}

//product pack
.pack-product-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-sm;
}
.mask {
  width: 55px;
}
.thumb-mask {
  padding: $spacer 0;
}
.pack-product__img {
  border: 1px solid $border-color;
}
.pack-product-name {
  flex-grow: 1;
  padding: 0 $spacer * 2;
}
.pack-product-quantity {
  border-left: 1px solid $border-color;
}
.pack-product-price {
  padding-right: $spacer/2;
}
.pack-product-quantity {
  display: flex;
  align-items: center;
  align-self: stretch;
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image {
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name {
  color: $secondary;
  font-weight: $font-weight-bold;
}
.product-total {
  font-weight: $font-weight-bold;
}
.cart-content {
  > p:not(:last-child) {
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image {
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right {
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      z-index: 99999999999;
      bottom: 0;
      background: $white;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, 0.15);
    }
    .modal-body {
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size * $btn-line-height) +
        $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image {
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading {
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection {
  justify-content: space-between;
  align-items: center;
}
.pack-miniature-item {
  margin-bottom: $spacer / 2;
}
.ratio1_1 {
  display: block;
}
.pack-product-name {
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product__card-img {
  position: relative;
}
.product__card-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quantity__label {
  margin-bottom: 0;
}
.product-add-to-cart {
  button {
    min-width: max-content;
    @include media-breakpoint-up(xxl) {
      flex:1
    }
  }
  .product-quantity {
    .btn-primary,
    .btn-primary-outlined {
      min-width: 240px;
    }

    @include media-breakpoint-down(xs) {
      button.add-to-cart,
      .config-btn {
        width: 100%;
      }
    }
  }
}

.product-customization {
  margin-bottom: $spacer * 1.5;
}
.product-discounts {
  margin-bottom: $spacer * 1.5;
}
.product__quantity {
  margin-bottom: 0;
  color: $gray-400;
}

.total__item-show {
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links {
  display: flex;
  justify-content: center;
}
.product__download {
  text-transform: uppercase;
}
.card--address {
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address {
  margin-top: $spacer;
}
.invoice-address {
  height: 100%;
  text-align: center;
  justify-content: center;
}
.pagination-summary {
  color: $gray-400;
}

@include media-breakpoint-mobile {
  #_mobile_search_filters .card-block {
    padding: 0 !important;
    box-shadow: none !important;
  }
}


.product-accessories {
  .product__card-img {
    margin-bottom: auto!important;
  }
  .product-miniature {
    margin-bottom: 0;
  }
}