header {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 21;
  flex-wrap: wrap;
  @include transition-fast;
  
}

// #module-rackcalculator-calculator header,
// #module-rackcalculator-shelfracks header,
// #module-rackcalculator-paletteracks header,
// #module-rackcalculator-pneuracks header,
// #module-rackcalculator-sufiveracks header,
// #module-rackcalculator-archiveracks header,
// #module-rackcalculator-cornerracks header,
// #module-rackcalculator-consoleracks header,
// #module-rackcalculator-stainlessracks header {
//   position: unset;
//   margin-bottom: 0!important;
// }

// #module-rackcalculator-calculator .header-top__content ,
// #module-rackcalculator-shelfracks .header-top__content ,
// #module-rackcalculator-paletteracks .header-top__content ,
// #module-rackcalculator-pneuracks .header-top__content ,
// #module-rackcalculator-sufiveracks .header-top__content ,
// #module-rackcalculator-archiveracks .header-top__content ,
// #module-rackcalculator-cornerracks .header-top__content ,
// #module-rackcalculator-consoleracks .header-top__content ,
// #module-rackcalculator-stainlessracks .header-top__content {
//   display: none!important;
// }
// #module-rackcalculator-calculator .header-main-content,
// #module-rackcalculator-shelfracks .header-main-content,
// #module-rackcalculator-paletteracks .header-main-content,
// #module-rackcalculator-pneuracks .header-main-content,
// #module-rackcalculator-sufiveracks .header-main-content,
// #module-rackcalculator-archiveracks .header-main-content,
// #module-rackcalculator-cornerracks .header-main-content,
// #module-rackcalculator-consoleracks .header-main-content,
// #module-rackcalculator-stainlessracks .header-main-content{
//   padding: 0.5rem 1rem;
// }

header img.logo.img-fluid {
  max-height: $spacer*4;
  width: auto;
  @include media-breakpoint-down(xl) {
    max-width: $spacer * 15;
  }

  @include media-breakpoint-down(sm) {
    max-width: $spacer *9.5;
  }

  @include media-breakpoint-down(xs) {
    max-width: $spacer *12;
  }

  @media (max-width:420px) {
      max-width: $spacer *9;
  }

  @media (max-width:370px) {
    max-width: $spacer *7.5;
}

 
}


@media ( max-width:360px) {
  .header-main-content svg, .header-main-content img, .header-main-content .wishlist-link.header__rightitem .header-item__icon i {
    max-height: 24px;
    max-width: 24px;
    font-size: 1.8rem;
}
}

@include media-breakpoint-down(sm) {
  .top-menu .fw-med {
    font-size: $font-size-base*.75;
}
}

header {
  transition: top ease-in 0.2s;
}

body.stucked {
  header {
    top: -50px;
  }
  // transform: translateY(-50px);
  
  
  // .header-main-content {
  //   padding: $spacer/2;
  // }
  // img.logo.img-fluid {
  //   max-height: 4rem;
  // }
}

.header-contact {
  border-bottom-left-radius:$border-radius-lg;
  border-bottom-right-radius:$border-radius-lg; 
}


/* Header Top Nav */

// .header-nav__left {
//   span,
//   a:not(.dropdown-item) {
//     color: $text-gray;
//   }
// }
@media (min-width: $menu-up) {
  .megamenu-mobile-toggle {
    display: none;
  }
}
.header-top__content .btn:not(.form-control-submit):not(.btn-inner) {
  padding: 0;
  
}

.header-top__content {
  height: 50px;
}

@include media-breakpoint-down(md) {
  .top-menu {width: 100%
  }}

.header-main-content {
  padding: $spacer;
  @include media-breakpoint-down(md) {
  svg,img {
    max-width: 24px;
    max-height: 24px;
  }}
}

.footer-bar__right {
  background-color: $header-nav-right-bg-color;
  @include transition-fast;
  svg {
    @include transition(inherit);
  }
  a {
    @include transition(inherit);
  }
  &:hover {
    background-color: darken($header-nav-right-bg-color, 5%);
    a {
      transform: translateY(-($spacer * 0.0625));
      svg {
        color: inherit;
      }
    }
  }
}

.header-nav__right a {
  font-weight: $font-weight-bolder;
  letter-spacing: 1px;
}

.header__logo--checkout {
  max-width: 200px;
}
.header-nav--checkout {
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@media (max-width:1024px) {
  .megamenu-mobile-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      line {
        transform-origin: center;
        @include transition-fast;
      }
      &.megamenu-open {
        line {
          &:nth-of-type(1) {
            opacity: 0;
          }
          &:nth-of-type(2) {
            transform: rotate(45deg) translateY(25%);
          }
          &:nth-of-type(3) {
            transform: rotate(-45deg) translateY(-25%);
          }
        }
      }
    }
    &:hover,
    &:active,
    &:focus {
      svg {
        color: $primary;
      }
    }
  }
}

.header-top {
  background: $backround-color;
  z-index: 9999999;
}

.top-banner p {
  color: $white;
}
