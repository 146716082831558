@mixin transition-fast {
  @include transition(.2s ease);
}

@mixin transition-slow {
  @include transition(.4s ease-out);
}

@mixin zoomin {
  transform: scale(1.03);
}

@mixin before-wrap {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin btn-hover($color: $btn-bg, $active-color: $btn-active-bg) {
  &:before, &:after {
    @include before-wrap;
    z-index: -1;
    @include border-radius(inherit);
  }
  &:before {
    background: $color;
  }
  &:after {
    background: $active-color;
    transform: translateX(-100%);
    @include transition(.3s cubic-bezier(.27,.17,.35,1.62));
  }
  &:not([disabled]):hover:after,
  &:not([disabled]):active:after,
  &:not([disabled]):focus:after {
    transform: translateX(0%);
  }
}


@mixin btn-hover-outline($color: $btn-bg, $active-color: $btn-active-bg) {
  &:before, &:after {
    @include before-wrap;
    z-index: -1;
    @include border-radius(inherit);
  }
  &:before {
    box-shadow: inset 0 0 0 1px $color;
    border-color: $color;
  }
  &:after {
    background: $active-color;
    transform: translateX(-100%);
    @include transition(.3s cubic-bezier(.27,.17,.35,1.62));
  }
  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
  &:hover:after,
  &:active:after,
  &:focus:after {
    transform: translateX(0%);
  }
}

// Scrollbar utilities

@mixin scrollbar(
  $size: thin,
  $foreground-color: $gray-300,
  $background-color: $light
) {
  @media (hover: hover) {

    $moz-size: auto;

    @if ($size == thin) {
      $moz-size: thin;
      $size: .5rem;
    }

    @if ($size == thick) {
      $size: .75rem;
    }

    // For Chrome & Safari
    &::-webkit-scrollbar {
      width: $size;
      height: $size;
    }
    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
    }
    &::-webkit-scrollbar-track {
      background: $background-color;
    }

    // Standard version (Firefox only for now)
    scrollbar-color: $foreground-color $background-color;
    scrollbar-width: $moz-size;
  }
}
