#usp {
    @include media-breakpoint-up(xl) {
        .item {
            border-right: solid 1px white;
        }
    }
    
    p {
        max-width: $spacer*15;
    }
}