button.slick-arrow, .fa-chevron-right, .fa-chevron-left {
  width: 25px;
  height: 25px;
  border: none;
  font-size: 0;
  margin: $spacer/2;
  background: url("/themes/profiregaly/assets/img/arrow-right.svg")
    center center no-repeat;
  background-size: contain;
  &.slick-prev {
    background: url("/themes/profiregaly/assets/img/arrow-left.svg")
      center center no-repeat;
  }
}

.fa-chevron-left {
  background: url("/themes/profiregaly/assets/img/arrow-left.svg") center center no-repeat;
}

#homeslider {
  button.slick-arrow {
    position: relative;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
}

.carousel-arrow-navigation {
  right: $spacer * 5;
  bottom: $spacer * 5;
  @include media-breakpoint-down(sm) {
    right: $spacer;
    bottom: $spacer;
  }
}

.carousel-image {
  border-radius: $btn-radius;
}

.configurator-carousel {
  width: calc(100% + #{$spacer * 2});
  margin-left: -$spacer;
  img {
    left: 0;
    top: 0;
    @include transition-fast;
  }
  a:hover img {
    @include zoomin; 
  }
}

// @include media-breakpoint-down(xl) {
// #homeslider {
// .carousel-image{
//   min-height: $spacer*30;
//   max-height:$spacer*30
// }

// .slick-slide img{

//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
// }}

#homeslider,
#discount-slider,
.product-miniature {
  .styled {
    background: $secondary;
    border-radius: $border-radius-lg;
    padding: $spacer;
    font-size: $small-font-size;
    max-width: max-content;
  }

  ul.slick-dots {
    display: flex !important;
  }

  .product__card-img {
    svg:hover, svg:hover path, .icon-secondary:hover, .icon-secondary:hover path {
      color:$primary!important;
      fill: $primary!important;
    }
    .nxtal-wishlist-icon:hover .icon-secondary {
      color:$primary!important;
      fill: $primary!important;
    }
  }

}

#homeslider {
.carousel-wrapper, .slick-slide, .carousel-image img {
  @include media-breakpoint-down(md) {
    min-height: $spacer*27;
  }}
}
.slider-content {
  background: linear-gradient(162deg, #1d1ea5, #2950e7);
  color: $white;
  top: 0;
  min-height: 100%;
  width: 50%;

  &::before {
    content: "";
    background: url(/themes/profiregaly/assets/img/slider-bg.svg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    background: linear-gradient(162deg, #1d1ea5f0, #2950e7cf);
    min-height: $spacer * 27;
  }

  * {
    z-index: 99999;
  }
  h1,
  h2 {
    font-size: clamp($spacer * 1.75, 3vw, #{$display2-size});
    max-width: $spacer * 35;
  }
}
