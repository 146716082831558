.has-dropdown {
  position: relative;
}


    .dropdown-content-wrapper.active {
      transform: translate(0, -$spacer) rotateX(0);
      transform-style: preserve-3d;
      opacity: 1;
      pointer-events: all;
      @include media-breakpoint-down(lg) { 
        opacity: 0;
      }
    }


  .dropdown-content-wrapper {
    min-width: min-content;
    margin-top: $spacer*2;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 20;

    opacity: 0;
    transform-origin: bottom center;
    transform: translate(0, -$spacer/2) rotateX(-15deg);
    @include transition(.3s ease);
    .dropdown-content {
        background: $white;
        box-shadow: $box-shadow-md;
        padding: $cart-preview-padding-y $cart-preview-padding-x;
        min-width: min-content;
      }
  &:not(.logged-in) {
    // min-width: $dropdown-min-width;
  }
}

.user-info .dropdown-content {
  &.logged-in {
    min-width: $spacer*10;
  }

  &:not(.logged-in) 
  {min-width: $spacer*25;}


  
}