.account-links{
  align-items: stretch;
  > a{
    margin-bottom: $spacer;
  }
  .link-item{
    box-shadow:$card-box-shadow;;
    padding: $spacer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    i, svg{
      margin: 0 auto $spacer!important;
      font-size: $font-size-base*3;
      fill:$gray-900;

    }
  }
}

.address-header{
  background-color: transparent;
}

.address-footer{
  display: flex;
  justify-content: space-between;

}

.form--100{
  min-width: 100%;
  width: 100%;
}
