header {
  .header-nav__round-icon {
    @include media-breakpoint-down(lg) {
      font-size: 0;
    }
  }  

  #poscompare-nb {
    &.hidden {
      visibility: hidden;
    }
  }
  

  /* Blockcart */

  .blockcart__count, .count-wishlist-product, #poscompare-nb {
    @include transition(0.15s ease);
    position: absolute;
    border-radius: 100%;
    width: $spacer*1.25;
    height: $spacer*1.25;
    right: 0;
    top:0;

    @include media-breakpoint-down(md) {
      right: -5px;
      top: -5px;
    }

    &:not(:empty) {
      background:$primary;
      color: white;
      display: flex;
    }
    &:empty {
      display: none;
      color: transparent;
    }

    &.hidden {
      visibility: hidden;
    }
  }

  .inactive .blockcart__count {
    display: none;
  }

  .shopping-cart > .blockcart__count {
    display: none;
}

  .inactive .shopping-cart a {
    pointer-events: none;
  }

}
